<template>
  <div class="modal-bg" @click="closeModal">
    <div class="modal-body">
      <div class="modal-head">
        {{ headerText }}
        <svg @click="closeModal"
             width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
        </svg>
      </div>
      <div class="modal-buttons">
        <div class="filled__grey" @click="success">{{ acceptButtonText }}</div>
        <div class="filled__yellow" @click="decline">{{ declineButtonText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmation-modal",
  props: {
    declineButtonText: String,
    acceptButtonText: String,
    headerText: String,
    successCallBack: Function
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    success() {
      this.$emit('success')
      if (this.successCallBack) {
        this.successCallBack()
      }
    },
    decline() {
      this.$emit('decline')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-bg {
  z-index: 900;
}

.modal-body {
  background-color: #3A3A3A;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 375px;
  width: 100%;
}

.modal-buttons {
  padding-top: 32px;
  padding-bottom: 58px;

  & > * {
    margin-bottom: 0;
    max-width: 200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

.modal-head {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #E2FF31;
  position: relative;
  padding-top: 30px;
  padding-bottom: 27px;
  text-align: center;
  border-bottom: 1px solid #898989;

  svg {
    cursor: pointer;
    position: absolute;
    right: -14px;
    top: 14px;
  }

}
</style>